/* body fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Playfair+Display:400,700');

@tailwind base;

@layer base {


    body {
        font-family: 'Playfair Display', serif;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
        color: #333;
    }

    @media (max-width: 767px) {
        body {
            font-size: 15px;
        }
    }

    p {
        color: #666;
        line-height: 28px;
        font-family: 'Playfair Display', serif;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Playfair Display', serif;
        color: #282e3f;
    }

}

@tailwind components;
@tailwind utilities;

.font-base-font {
    font-family: 'Playfair Display', serif;
}

.logo {
    padding: 18px 0;
}

.section-wrap {
    @apply py-[120px] md:py-[90px] sm:py-[80px] relative z-10
}

.wraper {
    @apply mx-auto max-w-[1540px] xl:px-[12px] lg:px-[12px] md:px-[20px] sm:px-[10px]
}

.theme-btn-2 {
    @apply text-base text-[#59C378] inline-block px-5 py-2 border border-[#496451] bg-transparent transition-all hover:bg-[#59C378] hover:border-[transparent] hover:text-white
}

.clip-your-needful-style {
    -webkit-clip-path: polygon(0 0, 100% 0, 55% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 55% 100%, 0% 100%);
}

#hubspotForm input,
#hubspotForm textarea {
    border: 3px solid #867662;
    background-color: rgba(190, 180, 153, 0.6);
    color: #fff;
    padding: 8px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 45px;
}

#hubspotForm textarea {
    height: 175px;
}

#hubspotForm label {
    display: none;
}

#hubspotForm input::placeholder,
#hubspotForm textarea::placeholder {
    color: #fff;
}

#hubspotForm fieldset {
    width: 100% !important;
    max-width: 100% !important;
}

#hubspotForm fieldset.form-columns-2 .hs-form-field:first-child {
    padding-right: 33px;
}

#hubspotForm fieldset.form-columns-2 .hs-form-field:last-child {
    padding-left: 33px;
}

#hubspotForm .submitted-message {
    color: #fff;
}

@media only screen and (max-width: 600px) {

    #hubspotForm fieldset.form-columns-2 .hs-form-field:first-child,
    #hubspotForm fieldset.form-columns-2 .hs-form-field:last-child {
        padding-right: 0;
        padding-left: 0;
    }
}

.hs_submit {
    width: 100%;
    position: relative;
}

#hubspotForm input.hs-button {
    width: 115px;
    padding: 5px;
    background-color: #867662;
    border: 3px solid #BEB499;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.addressContact span {
    display: inline-block;
    position: relative;
}